var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": _vm.headTitle,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-cancel": _vm.headCancel },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "150px",
                  disabled: _vm.formType == "view",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "formTopic",
                    staticStyle: { "margin-bottom": "12px" },
                  },
                  [_c("span", [_vm._v("企业信息")])]
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "企业名称", prop: "companyName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入企业名称",
                              },
                              model: {
                                value: _vm.dataForm.companyName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "companyName", $$v)
                                },
                                expression: "dataForm.companyName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "统一社会信用代码",
                              prop: "companyCode",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入统一社会信用代码",
                              },
                              model: {
                                value: _vm.dataForm.companyCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "companyCode", $$v)
                                },
                                expression: "dataForm.companyCode",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "注册地", prop: "regLocation" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入注册地",
                              },
                              model: {
                                value: _vm.dataForm.regLocation,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "regLocation", $$v)
                                },
                                expression: "dataForm.regLocation",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "法定代表人", prop: "legalRep" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入法定代表人",
                              },
                              model: {
                                value: _vm.dataForm.legalRep,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "legalRep", $$v)
                                },
                                expression: "dataForm.legalRep",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "成立日期", prop: "curDate" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: "",
                                placeholder: "请输入成立日期",
                              },
                              model: {
                                value: _vm.dataForm.curDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "curDate", $$v)
                                },
                                expression: "dataForm.curDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "formTopic",
                staticStyle: { "margin-bottom": "12px" },
              },
              [
                _c("span", [_vm._v("详情记录")]),
                _vm.formType != "view"
                  ? _c(
                      "el-button",
                      { attrs: { size: "mini" }, on: { click: _vm.addBtn } },
                      [_vm._v("新增")]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("addForm", {
              ref: "addForm",
              attrs: {
                companyCode: _vm.dataForm.companyCode,
                formType: _vm.formType,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }